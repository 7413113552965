import * as React from "react"
import Menu from "../components/menu"
import Footer from "../components/footer"
import { StaticImage } from "gatsby-plugin-image"
import { subtitle, title, textContainer } from "../styles"
import clsx from "clsx"
import Seo from "../components/seo"

// markup
const DetailDwPage = (props) => {
  return (
    <div> 
      <Seo />
      <Menu/>
      <div className="max-w-screen-xl mx-5 xl:mx-auto md:grid md:grid-cols-3 md:gap-8">

        <div className="max-w-screen-lg mt-12 text-center col-span-2">
          <StaticImage className="w-4/6 md:w-full"
            src="../images/dw-mockup.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-sm mt-12">
          <h1 className={ title }>
            Logo - Data Wizards
          </h1>
          <div className={ clsx(textContainer, "text-justify") }>
            <p className="mb-4">Creation of company branding identity for the company Data Wizards, based in Belgium. The objective for this project was to create a logo that would include the name of the company and would refer to the core business of that company Data Science & AI Consulting. Therefore we used both two first letters of the name to create a head/brain shaped logo to represent the idea of what data sciences is.</p>
            <p className="mb-4">In order to give a real sense to the branding identity I had created for my client, I build a design chart as a deliverable.</p>
          </div>

          <div className={ textContainer }>
            <h2 className = { subtitle }> Client </h2>
            <p className="mb-4">Data Wizards</p>
            <h2 className = { subtitle }> Type </h2>
            <p className="mb-4">Logo</p>
            <h2 className = { subtitle }> Year </h2>
            <p className="mb-4">2021</p>
          </div>
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/dw-01.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/dw-02.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/dw-03.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/dw-04.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/dw-05.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/dw-06.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-xl mx-auto mt-12 w-4/6 md:w-full col-span-3">
          <StaticImage className="w-6/6 md:w-full"
            src="../images/dw-07.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

      </div>
      <Footer/>
    </div>
     
  
  )
}


export default DetailDwPage